/* App.css */

body {
  margin: 0;
  padding: 0;
  height: 100vh; /* Full height */
  font-family: Arial, sans-serif; /* Use a clean, modern font */
}

.App {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Use full height */
  background-image: url('./assets/background.jpg'); /* Adjust the path to your image */
  background-size: cover; /* Ensures the image covers the full background */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
}

form {
  margin-bottom: 20px;
  background: rgba(255, 255, 255, 0.9); /* Light background for form */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Subtle shadow */
  padding: 20px; /* Space inside the form */
  width: 100%; /* Full width for responsiveness */
  max-width: 400px; /* Limit max width */
}

/* Keep the rest of your existing styles as they are... */
